import React from "react";
import "./style.scss";
import ScrollAnimation from "react-animate-on-scroll";

function HappyToHelp() {
  return (
    <div className="happy-to-help">
      <ScrollAnimation animateIn="fadeIn" duration={1}>
        <div className="main-text">We’re happy to help you!</div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" duration={1} delay={100}>
        <div className="will-pass">YOU WILL PASS</div>
      </ScrollAnimation>
    </div>
  );
}

export default HappyToHelp;
