import "./App.scss";
import Footer from "Components/Footer";
import Header from "Components/Header";
import About from "Components/About";
import HowItWorks from "Components/HowItWorks";
import SubscribeSection from "Components/SubscribeSection";
import FaqSection from "Components/FaqSection";
import HomeSection from "Components/HomeSection";
import PrepareExam from "Components/PrepareExam";
import ContactUs from "Components/ContactUs";
import { useEffect, useState } from "react";
import HappyToHelp from "Components/HappyToHelp";
import Affiliates from "Components/Affiliates";

const App = () => {
  const [activePage, setActivePage] = useState("lending");
  useEffect(() => {
    if (document.getElementById(activePage))
      setTimeout(
        () => document.getElementById(activePage).scrollIntoView(),
        300
      );
  }, [activePage]);
  return (
    <div className="App">
      <Header setActivePage={setActivePage} />
      <div className="landing">
        {activePage !== "contact" && activePage !== "affiliates" && (
          <>
            <HomeSection />
            <About />
            <HowItWorks />
            <HappyToHelp />
            <SubscribeSection />
            <FaqSection />
            <PrepareExam />
          </>
        )}
        {activePage === "contact" && <ContactUs />}
        {activePage === "affiliates" && <Affiliates />}
      </div>
      <Footer setActivePage={setActivePage} />
    </div>
  );
};

export default App;
