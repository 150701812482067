import {
  applePayIcon,
  googlePayIcon,
  lineSeparator,
  logoIcon,
  rightGreenIcon,
} from "assestes";
import React from "react";
import "./style.scss";

function SubscribeSection() {
  return (
    <div className="subscribe-section" id="Pricing">
      <div className="back-color"></div>
      <div className="wrapper">
        <div className="section">
          <div className="title">Subscribe For Just</div>
          <div className="price-block">
            <div className="price-section">
              <div className="logo-icon">
                <img src={logoIcon} alt="logoIcon" />
              </div>
              <div className="price-wrapper">
                <div className="price">
                  £12.<small>99</small>
                </div>
                <div className="duration">*per month</div>
              </div>
            </div>
          </div>
          <div className="price-points">
            <div className="point">
              <div className="icon">
                <img src={rightGreenIcon} alt="rightGreenIcon" />
              </div>
              <div className="text">Unlimited access to routes</div>
            </div>
            <div className="point">
              <div className="icon">
                <img src={rightGreenIcon} alt="rightGreenIcon" />
              </div>
              <div className="text">Practise multiple centres</div>
            </div>
          </div>
          <div className="price-points">
            <div className="point">
              <div className="icon">
                <img src={rightGreenIcon} alt="rightGreenIcon" />
              </div>
              <div className="text">Four countries available</div>
            </div>
            <div className="point">
              <div className="icon">
                <img src={rightGreenIcon} alt="rightGreenIcon" />
              </div>
              <div className="text">You can cancel anytime</div>
            </div>
          </div>
          <div className="purchase-through">
            <div className="purchase-through-title">
              In app purchase through
            </div>
            <div className="pay-options-wrapper">
              <div className="pay-options">
                <img src={googlePayIcon} alt="googlePayIcon" />
                <img src={lineSeparator} alt="lineSeparator" />
                <img src={applePayIcon} alt="applePayIcon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscribeSection;
