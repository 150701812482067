import React from "react";
import "./style.scss";

function Affiliates() {
  return (
    <div className="contact-us" id="contact">
      <div className="title">
        Please email us at{" "}
        <a href="mailto:info@rutesapp.com">info@rutesapp.com</a> asking to
        partner up as an affiliate
      </div>
    </div>
  );
}

export default Affiliates;
