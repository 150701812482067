import { facebookIcon, instaIcon, logoFooter, twitterIcon } from "assestes";
import React from "react";
import "./style.scss";

function Footer(props) {
  const { setActivePage } = props;
  const footerAboutMenu = (
    <div className="menu-options">
      <div
        className="menu-item"
        onClick={() => {
          setActivePage("about-section");
          // document.getElementById("about-section").scrollIntoView();
        }}
      >
        About
      </div>
      <div
        className="menu-item"
        onClick={() => {
          setActivePage("Pricing");
          // document.getElementById("Pricing").scrollIntoView();
        }}
      >
        Pricing
      </div>
      <div
        className="menu-item"
        onClick={() => {
          setActivePage("faq-section");
          // document.getElementById("faq-section").scrollIntoView();
        }}
      >
        FaQ
      </div>
      <div
        className="menu-item"
        onClick={() => {
          setActivePage("contact");
        }}
      >
        Contact
      </div>
    </div>
  );
  const footerTnCMenu = (
    <div className="menu-options">
      {/* <div className="menu-item">Terms of Service</div>
      <div className="menu-item">Cookies</div> */}
    </div>
  );
  return (
    <div className="footer-wrapper">
      <div className="footer-section">
        <div className="section-container">
          <div className="logo-section">
            <img src={logoFooter} alt="logoFooter" />
          </div>
          {window.innerWidth > 600 ? (
            <>
              {footerAboutMenu}
              {/* {footerTnCMenu} */}
            </>
          ) : (
            <>
              <div className="mobile-footer-menu">
                {footerAboutMenu}
                {/* {footerTnCMenu} */}
              </div>
            </>
          )}
          <div className="social">
            <img src={facebookIcon} alt="facebookIcon" />
            <img src={instaIcon} alt="instaIcon" />
            <img src={twitterIcon} alt="twitterIcon" />
          </div>

          {/* <div className="stay-in-touch">
            <div className="title">Stay in touch</div>
            <div className="input-wrapper">
              <input placeholder="Email Address" />
              <div className="btn-sign-up">Sign up</div>
            </div>
            <div className="signup-note">
              *You will receive our Newsletter once per month, you can cancel it
              anytime.
            </div>
          </div> */}
        </div>
        <hr></hr>
        <div className="footer-bottom">
          <div className="copyright">Copyright © 2024 Rutes</div>
          <div className="rights-reserved">All rights Reserved</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
