import {
  drivingCenterIcon,
  lineLeftFad,
  lineRightFad,
  mobileHowWork,
  navigateIcon,
  testRouteIcon,
} from "assestes";
import React from "react";
import "./style.scss";

function HowItWorks() {
  return (
    <div className="how-it-work">
      <div className="main-title">How it Works?</div>
      <div className="mobile-how-it-work">
        <img src={mobileHowWork} alt="mobileHowWork" />
      </div>
      <div className="steps">
        <div className="how-it-work-wrapper">
          <div className="work-item">
            <img
              src={drivingCenterIcon}
              alt="drivingCenterIcon"
              className="icon"
            />
            <div className="work-item-text-wrapper">
              <div className="title">Pick Driving Center</div>
              <div className="sub-title">
                Find and select your desired driving school Center.
              </div>
            </div>
          </div>
          <div className="work-item">
            <img src={testRouteIcon} alt="testRouteIcon" className="icon" />
            <div className="work-item-text-wrapper">
              <div className="title">Pick Test Route</div>
              <div className="sub-title">
                Pick one of official driving exam test routes from our list.
              </div>
            </div>
          </div>
          <div className="work-item">
            <img src={navigateIcon} alt="navigateIcon" className="icon" />
            <div className="work-item-text-wrapper">
              <div className="title">Navigate</div>
              <div className="sub-title">
                Practice Route and get ready to pass your driving test.
              </div>
            </div>
          </div>
        </div>
        <div className="dashed-line-wrapper">
          <div class="dashed-line">
            <img
              src={lineLeftFad}
              alt="lineLeftFad"
              className="line-left-fad"
            />
            <img
              src={lineRightFad}
              alt="lineRightFad"
              className="line-right-fad"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
