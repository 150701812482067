import React, { useRef } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import "./style.scss";
import { blueCheckIcon } from "assestes";
import axios from "axios";
import _ from "lodash";
import { useToasts } from "react-toast-notifications";
import { apiDomain, hCaptchaSitekey } from "constant";
import HCaptcha from "@hcaptcha/react-hcaptcha";

function ContactUs() {
  const captchaRef = useRef(null);
  const { addToast } = useToasts();
  const initialValues = {
    name: "",
    email: "",
    message: "",
    tnc: false,
    submit: null,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(255).required("* Name is required"),
    email: Yup.string().max(255).required("* Email is required"),
    message: Yup.string().max(255).required("* Message is required"),
    tnc: Yup.boolean().oneOf([true], "This field must be checked"),
    hCaptcha: Yup.string().required("Captcha is required"),
  });
  return (
    <div className="contact-us" id="contact">
      <div className="title">Contact us</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (
          values,
          { setErrors, setStatus, setSubmitting, setFieldValue }
        ) => {
          try {
            await axios.post(`${apiDomain}/contact`, {
              FullName: values.name,
              Email: values.email,
              Message: values.message,
              CaptchaCode: values.hCaptcha,
            });
            addToast(`Request Sent`, {
              appearance: "success",
              autoDismiss: true,
              PlacementType: "top-right",
            });
            captchaRef.current?.resetCaptcha();
            setFieldValue("hCaptcha", "");
            setSubmitting(false);
          } catch (err) {
            captchaRef.current?.resetCaptcha();
            setSubmitting(false);
            setErrors({
              submit: _.get(err, "response.data.title", ""),
            });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="two-input">
              <div className="input-wrapper">
                <div className="input-title">FULL NAME</div>
                <div className="input">
                  <input
                    placeholder="John Doe"
                    name="name"
                    className={`${touched.name && errors.name ? "error" : ""}`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <div className="input-title">Your Email</div>
                <div className="input">
                  <input
                    placeholder="email@example.com"
                    name="email"
                    className={`${
                      touched.email && errors.email ? "error" : ""
                    }`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                  />
                </div>
              </div>
            </div>
            <div className="input-wrapper">
              <div className="input">
                <textarea
                  placeholder="Your Message Goes Here..."
                  rows={11}
                  className={`${
                    touched.message && errors.message ? "error" : ""
                  }`}
                  name="message"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.message}
                />
              </div>
            </div>
            <div className="captcha">
              <HCaptcha
                ref={captchaRef}
                sitekey={hCaptchaSitekey}
                onVerify={(token) => {
                  setFieldValue("hCaptcha", token);
                }}
                onExpire={() => {
                  setFieldValue("hCaptcha", "");
                }}
              />
              {Boolean(errors.hCaptcha) && (
                <div className="error">{errors.hCaptcha}</div>
              )}
            </div>
            {errors.submit && (
              <div className="sumbit-error">{errors.submit}</div>
            )}
            <div className="submit-section">
              <div className="tnc">
                <div
                  className="icon"
                  onClick={() => setFieldValue("tnc", !values.tnc)}
                >
                  <img
                    src={blueCheckIcon}
                    alt="blueCheckIcon"
                    className={values.tnc ? "checked" : "unchecked"}
                  />
                </div>
                <div
                  className={`text ${touched.tnc && errors.tnc ? "error" : ""}`}
                >
                  I agree with our <b>Privacy Policy</b>
                </div>
              </div>
              <div className="btn-submit" onClick={() => handleSubmit()}>
                <div className="btn">Send Message</div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ContactUs;
