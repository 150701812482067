import React from "react";
import Faq from "faq";
import { Accordion } from "react-accessible-accordion";
import "./style.scss";

function FaqSection() {
  return (
    <div className="faq-section" id="faq-section">
      <div className="title">FaQS</div>
      <Accordion allowZeroExpanded>
        {[
          {
            que: "How can I download the driving test routes on my mobile app?",
            ans: "It's super easy! Simply head over to the Apple App Store or Google Play Store and search for our app. Once you find it, click on the download button, and the app will be installed on your device in no time. Get ready to ace those driving test routes!",
          },
          {
            que: "Are the driving test routes available for all types of vehicles (e.g., cars, motorcycles)?",
            ans: "Currently, our driving test routes are tailored for cars. However, we have exciting plans to expand our offerings to include routes for other types of vehicles like motorcycles in the near future.",
          },
          {
            que: "Can I access driving test routes for different countries or cities?",
            ans: "Absolutely! We understand that driving test routes can vary depending on regions and cities. That's why we strive to provide access to a wide range of driving test routes across different areas and countries. Check out our app to see the countries and cities we currently cover, and keep an eye out as we continue to expand our coverage.",
          },
          {
            que: "Are the driving test routes updated regularly to reflect any changes in road conditions or regulations?",
            ans: "Yes, indeed! We take pride in keeping our driving test routes up to date.You can rely on our app to provide you with the most relevant and accurate information.",
          },
          {
            que: "Can I track my progress and performance while practicing the driving test routes?",
            ans: "Yes. We've included a progress tracking feature in our app to help you monitor your performance while practicing the driving test routes. You can easily rate each route your complete, track the routes you've completed, and identify areas where you can improve. It's a great tool to enhance your preparation and boost your confidence.",
          },
          {
            que: "Will I have offline access to the driving test routes once I download them?",
            ans: "Unfortunately, no. To provide you with real-time navigation and ensure the most accurate experience, our app requires internet access to operate. This allows us to provide you with any updates or changes to the driving test routes as they occur. So make sure you have a stable internet connection while using our app for the best results.",
          },
        ].map((f, i) => (
          <Faq que={f.que} ans={f.ans} type={f.type} key={i} />
        ))}
      </Accordion>
    </div>
  );
}

export default FaqSection;
