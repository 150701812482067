import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ParallaxProvider } from "react-scroll-parallax";
import { ToastProvider } from "react-toast-notifications";
import "react-accessible-accordion/dist/fancy-example.css";

ReactDOM.render(
  <ParallaxProvider>
    <ToastProvider>
      <App />
    </ToastProvider>
  </ParallaxProvider>,
  document.getElementById("root")
);
