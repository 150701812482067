import { aboutImg } from "assestes";
import React from "react";
import "./style.scss";

function About() {
  return (
    <div className="about-section" id="about-section">
      <div className="left">
        <img src={aboutImg} alt="aboutImg" />
      </div>
      <div className="right">
        <div className="title">About App</div>
        <div className="details">
          Our app is made to prepare you to pass your driving test exam. With
          Rutes app, you can easily access all the driving test routes in your
          country to ensure you're fully prepared for your driving exam. Let’s
          build your confidence behind the wheel!
        </div>
        {/* <div className="number-wrapper">
          <div className="number-item">
            <div className="number">965</div>
            <div className="number-title">Routes</div>
          </div>
          <div className="number-item">
            <div className="number">350</div>
            <div className="number-title">Schools</div>
          </div>
          <div className="number-item">
            <div className="number">100k</div>
            <div className="number-title">Downloads</div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default About;
