import { circleDownBlueIcon, circleUpBlueIcon } from "assestes";
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const Faq = ({ que, ans, type }) => {
  // const [isOpen, setIsOpen] = useState(false);
  let answerSec = <div>{ans}</div>;
  if (type === "div-list") {
    answerSec = (
      <>
        {ans.map((a) => (
          <div>{a}</div>
        ))}
      </>
    );
  }
  if (type === "list") {
    answerSec = (
      <>
        <ul>
          {ans.map((a) => (
            <li>{a}</li>
          ))}
        </ul>
      </>
    );
  }
  return (
    <>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton arra>
            <div className="faq-q">
              <span>{que}</span>
              <div className="icon">
                <AccordionItemState>
                  {({ expanded }) =>
                    expanded ? (
                      <img src={circleUpBlueIcon} alt="circleUpBlueIcon" />
                    ) : (
                      <img src={circleDownBlueIcon} alt="circleDownBlueIcon" />
                    )
                  }
                </AccordionItemState>
              </div>
            </div>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{answerSec}</AccordionItemPanel>
      </AccordionItem>
      {/* <div className="qna">
        <div className="que" onClick={() => setIsOpen(!isOpen)}>
          {que} {isOpen ? <ExpandLess /> : <ExpandMore />}
        </div>
        <div className={`ans ${isOpen ? "open" : ""}`}>{answerSec}</div>
      </div> */}
    </>
  );
};

export default Faq;
