import { logo } from "assestes";
import React, { useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import "./style.scss";

function Header(props) {
  const { setActivePage } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="header-wrapper">
      <div className="header">
        <div className="logo">
          <img src={logo} alt="logo" onClick={() => setActivePage("lending")} />
        </div>
        <div className="c-menu"></div>
        <div className="r-menu">
          <span className="r-menu-wrapper">
            <div className="menu-items">
              <div
                className="menu-item faq"
                onClick={() => {
                  setActivePage("about-section");
                  //   document.getElementById("about-section").scrollIntoView();
                }}
              >
                About
              </div>
              <div
                className="menu-item faq"
                onClick={() => {
                  setActivePage("Pricing");
                  //   document.getElementById("Pricing").scrollIntoView();
                }}
              >
                Pricing
              </div>
              <div
                className="menu-item faq"
                onClick={() => {
                  setActivePage("faq-section");
                  //   document.getElementById("faq-section").scrollIntoView();
                }}
              >
                FaQ
              </div>
              <div
                className="menu-item faq"
                onClick={() => setActivePage("contact")}
              >
                Contact
              </div>
              <div
                className="menu-item faq"
                onClick={() => setActivePage("affiliates")}
              >
                Affiliates
              </div>
              <div className="menu-item mobile-only">
                <MenuIcon
                  className="mobile-only"
                  style={{ width: "40px", height: "40px" }}
                  onClick={() => {
                    setMenuOpen(!menuOpen);
                  }}
                />
              </div>
            </div>
          </span>
        </div>
        {menuOpen && (
          <div className={`mobile-menu ` + (menuOpen ? "active" : "")}>
            <div
              className="menu-item"
              onClick={() => {
                document.getElementById("about-section").scrollIntoView();
                setMenuOpen(false);
              }}
            >
              About
            </div>
            <div
              className="menu-item"
              onClick={() => {
                document.getElementById("Pricing").scrollIntoView();
                setMenuOpen(false);
              }}
            >
              Pricing
            </div>
            <div
              className="menu-item"
              onClick={() => {
                document.getElementById("faq-section").scrollIntoView();
                setMenuOpen(false);
              }}
            >
              FaQ
            </div>
            <div
              className="menu-item"
              onClick={() => {
                document.getElementById("pricing-section").scrollIntoView();
                setMenuOpen(false);
              }}
            >
              Contact
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
